import React, { useContext } from "react";
import Penguin2 from "./penguin";
import DarkModeIcon from '../icons/darkmode.svg'
import LightModeIcon from '../icons/lightmode.svg'

import './Header.scss';
import { DarkModeContext } from "../App";

export default function Header() {
  const { isDarkMode, setIsDarkMode } = useContext(DarkModeContext);

  return <header className="header">
    <p className="header-left">hi@syednabilashraf<span className="header-left-underscore"> _</span></p>
    <Penguin2 />
    <nav className="header-right">
      {/* <a>Projects</a> */}
      <img className="header-icon" src={isDarkMode ? LightModeIcon : DarkModeIcon} onClick={() => setIsDarkMode(!isDarkMode)}></img>
    </nav>
  </header >
}

