import { createContext, useContext, useState } from 'react';
import './App.scss'
import AboutMe from './components/AboutMe';
import Education from './components/Education';
import Experience from './components/Experience';
import Footer from './components/Footer';
import Header from './components/Header';
import Presentation from './components/Presentation';
import ResearchExperience from './components/ResearchExperience';

export const DarkModeContext = createContext(true);

function App() {
  const [isDarkMode, setIsDarkMode] = useState(true);

  const onMouseMove = (e) => {
    const eyes = document.querySelectorAll(".penguin-eye");
    [].forEach.call(eyes, function(eye) {
      let mouseX = eye.getBoundingClientRect().right;
      if (eye.classList.contains("penguin-eye-left")) {
        mouseX = eye.getBoundingClientRect().left;
      }
      let mouseY = eye.getBoundingClientRect().top;
      let radianDegrees = Math.atan2(e.pageX - mouseX, e.pageY - mouseY);
      let rotationDegrees = radianDegrees * (180 / Math.PI) * -1 + 180;
      eye.style.transform = `rotate(${rotationDegrees}deg)`;
    });
  }
  return <DarkModeContext.Provider value={{ isDarkMode, setIsDarkMode }}>
    <div className={`App ${isDarkMode ? 'dark' : 'light'}`} onMouseMove={onMouseMove}>
      <Header />
      <div className='App-body'>
        <AboutMe />
        <Education />
        {/* <Presentation/> */}
        {/* <ResearchExperience/> */}
        <Experience />
      </div>
      <Footer />

    </div ></DarkModeContext.Provider>
}

export default App;
