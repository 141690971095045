import React from "react";
import './penguin.scss'

export default function Penguin() {
    return <>
        <div className="penguinContainer">
            <div className="penguin">
                <div className="penguin-body">
                    <div className="penguin-mouth"></div>
                    <div className="penguin-cheek">
                        <div className="penguin-cheek-left"></div>
                        <div className="penguin-cheek-right"></div>
                    </div>

                </div>
                <div className="body-top-left">
                    <div className="penguin-eye penguin-eye-left">
                        <div className="penguin-eye-pupil"></div>
                    </div>
                </div>
                <div className="body-top-right">
                    <div className="penguin-eye penguin-eye-right">
                        <div className="penguin-eye-pupil"></div>
                    </div>
                </div>
                <div className="penguin-arm">
                    <div className="penguin-arm-left"></div>
                    <div className="penguin-arm-right"></div>
                </div>

                <div className="penguin-feet penguin-feet-left"></div>
                <div className="penguin-feet penguin-feet-right"></div>
            </div>
        </div>
    </>
}