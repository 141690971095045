import React from "react";
import './Education.scss'

export default function Education() {
    return <div>
        <h2 className="education-title App-sectionHeader">Education</h2>
        <div className='education-details'>
            <p className="education-details-degreeName"><strong>BSc. in Computer Science and Engineering</strong>, BRAC University</p>
            <date>January 2017 - June 2021</date>
        </div>
        <p>Thesis: <em>Early Schizophrenia Diagnosis with 3D Convolutional Neural Network</em></p>
    </div>
}