import React from "react";
import './Experience.scss'

export default function Experience() {
    return <div className='experience'>
        <h2 className='experience-title App-sectionHeader'>Industry Experience</h2>
        {/* Optimizely */}
        <div className='experience-headerContainer'>
            <div>
                <p className='experience-headerContainer-role'>Software Engineer</p>
                <a className='experience-headerContainer-companyName' href="https://www.optimizely.com/" target='_blank'>Optimizely</a>
            </div>
            <date className='experience-headerContainer-duration'>March 2022 - Present</date>
        </div>
        <ul>
            <li>
                Introduced analytics support for CMS contents which allowed marketers to track different content engagement metrics.
            </li>
            <li>
                Improved test coverage by 75% in new headless CMS integration with RTL, Pytest to reduce regression
            </li>
            <li>
                Implemented consumption of new webhook event which allowed marketers to view error generated by a downstream CMS
            </li>
            <li>
                Addressed significant tech debt by migrating a core platform logic from legacy maintained AngularJS to React code
            </li>
        </ul>
        {/* BS23 */}
        <div className='experience-headerContainer'>
            <div>
                <p className='experience-headerContainer-role'>Associate Software Engineer</p>
                <a className='experience-headerContainer-companyName' href="https://brainstation-23.com/?bc" target='_blank'>Brain Station 23</a>
            </div>
            <date className='experience-headerContainer-duration'>January 2022 - March 2022</date>
        </div>
        <ul>
            <li>
                Architected a boilerplate e-commerce website meant to be tailored to needs of individuals using NestJS, NextJS
            </li>
            <li>
                Addressed tech debt in a legacy maintained MEAN stack platform
            </li>
            <li>
                Performed requirement analysis, code review
            </li>
        </ul>
        {/* TechHack */}
        <div className='experience-headerContainer'>
            <div>
                <p className='experience-headerContainer-role'>Software Engineer Intern</p>
                <a className='experience-headerContainer-companyName' href="https://www.linkedin.com/company/techhack/?originalSubdomain=ca" target='_blank'>TechHack Canada</a>
            </div>
            <date className='experience-headerContainer-duration'>September 2021 -  January 2021</date>
        </div>
        <ul>
            <li>
                Developed and tested backend endpoints for several features in NodeJS for a recruiting platform startup
            </li>
            <li>
                Introduced caching with Redis for certain APIs which improved response time when fetching large data
            </li>
            <li>
                Added search functionality in backend to allow a company to filter developers based on different criteria
            </li>
            <li>
                Implemented several file upload features using AWS S3
            </li>
        </ul>
    </div>
}
